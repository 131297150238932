<template>
  <v-container id="payments-packs" fluid tag="section" class="container-full-size">
    <v-data-table
      :headers="headers"
      :items="itemsFiltered"
      :search="search"
      :no-data-text="str['payments_packs_empty']"
      :no-results-text="str['payments_packs_empty']"
      class="elevation-1"
      @click:row="openItem"
    >
      <template #top>
        <v-toolbar flat>
          <v-text-field v-model="search" append-icon="mdi-magnify" :label="str['search']" single-line hide-details />
          <v-spacer />
          <v-btn v-if="editPermission" color="success" dark class="mb-2" @click="openItem()">
            {{ str['add'] }}
          </v-btn>
          <v-dialog v-model="dialogItem" persistent max-width="850px">
            <v-card>
              <v-card-title>
                <span class="headline">
                  {{ itemToEdit ? str['edit_payment_pack'] : str['new_payment_pack'] }}
                </span>
              </v-card-title>
              <v-card-text class="pt-2">
                <v-container>
                  <v-row>
                    <v-col
                      v-for="item in visibleData"
                      :key="item.id"
                      :cols="item.cols ? item.cols : 12"
                      :class="[
                        item.type === 'checkbox' ? 'pb-0' : '',
                        item.disabled ? 'custom-disabled' : '',
                        item.labelCallback || item.labelText ? 'row-flex-align-space' : '',
                      ]"
                    >
                      <v-text-field
                        v-if="item.type === 'input' && item.subType !== 'numeric'"
                        v-model="item.value"
                        :label="item.title + (item.required ? ' *' : '')"
                        :disabled="item.disabled ? true : false"
                        hide-details
                      />
                      <v-text-field
                        v-if="item.type === 'input' && item.subType === 'numeric'"
                        v-model="item.value"
                        :label="item.title + (item.required && !item.disabled ? ' *' : '')"
                        :disabled="item.disabled ? true : false"
                        :style="{ 'max-width': item.width }"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                        hide-details
                        @input="item.onChange ? item.onChange(item) : null"
                      />
                      <v-textarea
                        v-if="item.type === 'textarea'"
                        v-model="item.value"
                        :label="item.title + (item.required ? ' *' : '')"
                        :disabled="item.disabled ? true : false"
                        hide-details
                      />
                      <v-autocomplete
                        v-if="item.type === 'select'"
                        v-model="item.value"
                        :label="item.title + (item.required && !item.disabled ? ' *' : '')"
                        item-text="label"
                        item-value="value"
                        :items="item.items"
                        :multiple="item.multiple"
                        :no-data-text="str['no_data']"
                        hide-details
                        @change="refreshData(item)"
                      />
                      <v-checkbox
                        v-if="item.type === 'checkbox'"
                        v-model="item.value"
                        :label="item.title"
                        class="form-field-checkbox"
                        hide-details
                        @change="refreshData(item)"
                      />
                      <v-menu
                        v-if="item.type === 'datepicker'"
                        v-model="item.menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template #activator="{ on, attrs }">
                          <v-text-field
                            v-model="item.value"
                            :label="item.title + (item.required && !item.disabled ? ' *' : '')"
                            readonly
                            v-bind="attrs"
                            hide-details
                            v-on="on"
                          />
                        </template>
                        <v-date-picker v-model="item.value" style="margin: 0" :locale="datepickerLanguage" @change="item.menu = false" />
                      </v-menu>
                      <div v-if="item.labelCallback" class="pl-4 success--text">
                        {{ item.labelCallback() }}
                      </div>
                      <div v-if="item.labelText" class="pl-4 success--text">
                        {{ item.labelText }}
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="default" @click="closeItem">
                  {{ str['cancel'] }}
                </v-btn>
                <v-btn color="success" @click="saveItem">
                  {{ str['save'] }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-toolbar flat class="toolbar-adjust">
          <v-row>
            <v-col cols="12">
              <div class="row-flex-align">
                <v-autocomplete
                  v-model="statusFilter"
                  :label="str['status']"
                  item-text="label"
                  item-value="value"
                  :items="statusList"
                  :no-data-text="str['no_data']"
                  hide-details
                  class="select-size"
                  @change="getItems()"
                />
                <v-autocomplete
                  v-if="hasCategory"
                  v-model="categoryFilter"
                  :label="str['category']"
                  item-text="label"
                  item-value="value"
                  :items="categories"
                  :no-data-text="str['no_data']"
                  clearable
                  hide-details
                  class="select-size ml-4"
                />
              </div>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template #item.category="{ item }">
        {{ categoriesDict[item.category] }}
      </template>
      <template #item.type="{ item }">
        {{ getType(item) }}
      </template>
      <template #item.price="{ item }">
        {{ item.price ? item.price / 100 : '' }}
      </template>
      <template #item.signup_fee="{ item }">
        {{ item.signup_fee ? item.signup_fee / 100 : '-' }}
      </template>
      <template #item.allow_recurring="{ item }">
        {{ item.allow_recurring ? str['yes'] : str['no'] }}
      </template>
      <template #item.allow_one_time_payments="{ item }">
        {{ item.allow_one_time_payments ? str['yes'] : str['no'] }}
      </template>
      <template #item.charge_day="{ item }">
        {{ item.charge_day ? item.charge_day : '-' }}
      </template>
      <template #item.allow_installments="{ item }">
        {{ item.allow_installments ? str['yes'] : str['no'] }}
      </template>
      <template #item.temp="{ item }">
        {{ item.temp ? str['yes'] : str['no'] }}
      </template>
      <template #item.allow_contents="{ item }">
        {{ item.allow_contents ? str['yes'] : str['no'] }}
      </template>
      <template #item.moloni_product="{ item }">
        {{ item.moloni_product ? item.moloni_product : '-' }}
      </template>
      <template #item.end_date="{ item }">
        {{ item.temp ? item.end_date : '-' }}
      </template>
      <template #item.url="{ item }">
        <div>
          <a v-if="item.url" target="_blank" :href="item.url" @click.stop>
            {{ str['link'] }}
          </a>
          <span v-if="!item.url">-</span>
        </div>
      </template>
      <template #item.status="{ item }">
        <span :class="[statusDict[item.status] ? statusDict[item.status].type + '--text' : '']">
          {{ statusDict[item.status] ? statusDict[item.status].label : item.status }}
        </span>
      </template>
      <template #item.actions="{ item }">
        <v-icon v-if="editPermission" small class="mr-0" color="error" @click.stop="deleteItem(item)">mdi-delete</v-icon>
      </template>
      <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import Api from '@/services/Api'
import Utils from '@/services/Utils'
import moment from 'moment'

export default {
  data() {
    const user = Utils.getUser()
    const editPermission = Utils.hasPermission('payments_packs_edit') ? true : false
    const categoriesDict = this.getCategoriesDict()
    const hasSaleContentsCategory = 99
    return {
      str: window.strings,
      datepickerLanguage: window.datepickerLanguage,
      user: user,
      editPermission: editPermission,
      hasCategory:
        user && user.configurations && user.configurations.payments_packs && user.configurations.payments_packs.has_category ? true : false,
      hasChargeDay:
        user && user.configurations && user.configurations.payments_packs && user.configurations.payments_packs.has_charge_day
          ? true
          : false,
      hasSignupFee:
        user && user.configurations && user.configurations.payments_packs && user.configurations.payments_packs.has_signup_fee
          ? true
          : false,
      hasMoloni:
        user && user.configurations && user.configurations.payments_packs && user.configurations.payments_packs.has_moloni ? true : false,
      hasEmployees:
        user && user.configurations && user.configurations.payments_packs && user.configurations.payments_packs.has_employees
          ? true
          : false,
      hasDescription:
        user && user.configurations && user.configurations.payments_packs && user.configurations.payments_packs.has_description
          ? true
          : false,
      hasForm:
        user && user.configurations && user.configurations.payments_packs && user.configurations.payments_packs.has_form ? true : false,
      hasSaleContentsCategory: hasSaleContentsCategory,
      hasSaleContents: categoriesDict[hasSaleContentsCategory] ? true : false,
      headers: [],
      items: [],
      dialogItem: false,
      data: [],
      search: '',
      itemToEdit: null,
      statusList: Utils.getPaymentsPacksStatus(),
      statusFilter: 0,
      statusDict: this.getStatusDict(),
      categoryFilter: null,
      categories: Utils.getPaymentsPacksCategories(),
      categoriesDict: categoriesDict,
      contentsMinType: 99,
    }
  },
  computed: {
    visibleData() {
      return this.data.filter((item) => item.visible)
    },
    itemsFiltered() {
      const self = this
      let items = this.items
      if (this.hasCategory && this.categoryFilter !== null && this.categoryFilter !== '') {
        items = items.filter(function (item) {
          return item.category === self.categoryFilter
        })
      }
      return items
    },
  },
  watch: {
    dialogItem(val) {
      const dialog = document.getElementsByClassName('v-dialog')
      if (dialog && dialog[0]) {
        dialog[0].scrollTo(0, 0)
      }
      val || this.closeItem()
    },
  },
  beforeMount: function () {
    if (!this.user) {
      return false
    }
    this.getItems()
    this.setHeaders()
    this.setFields()
  },
  methods: {
    getStatusDict: function () {
      const list = Utils.getPaymentsPacksStatus()
      const dict = {}
      if (list && list.length) {
        list.forEach(function (item) {
          dict[item.value] = item
        })
      }
      return dict
    },
    getCategoriesDict: function () {
      const list = Utils.getPaymentsPacksCategories()
      const dict = {}
      if (list && list.length) {
        list.forEach(function (item) {
          dict[item.value] = item.label
        })
      }
      return dict
    },
    getItems: function () {
      const self = this
      this.$isLoading(true)
      Api.getPaymentsPacks(
        {
          status: this.statusFilter,
        },
        function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.items = response.data
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    setHeaders: function () {
      const paymentTypes = Utils.getPaymentTypes()
      const paymentType = paymentTypes
        ? paymentTypes.find(function (p) {
            return p.value === window.gatewayType
          })
        : null
      const data = [
        {
          text: window.strings['name'],
          value: 'name',
          align: 'left',
          width: 120,
          validate: true,
        },
        {
          text: window.strings['type'],
          value: 'type',
          align: 'center',
          width: 120,
          validate: true,
        },
        {
          text: window.strings['price'],
          value: 'price',
          align: 'center',
          width: 100,
          validate: true,
        },
        {
          text: window.strings['subscription'],
          value: 'allow_recurring',
          align: 'center',
          width: 110,
          validate: true,
        },
        {
          text: window.strings['one_time_payment'],
          value: 'allow_one_time_payments',
          align: 'center',
          width: 140,
          validate: true,
        },
        {
          text: window.strings['staggered_payments'],
          value: 'allow_installments',
          align: 'center',
          width: 160,
          validate: true,
        },
        {
          text: window.strings['temporary'],
          value: 'temp',
          align: 'center',
          width: 110,
          validate: true,
        },
        {
          text: window.strings['contents'],
          value: 'allow_contents',
          align: 'center',
          width: 110,
          validate: true,
        },
        {
          text: window.strings['start_date'],
          value: 'start_date',
          align: 'center',
          width: 120,
          validate: true,
        },
        {
          text: window.strings['end_date'],
          value: 'end_date',
          align: 'center',
          width: 120,
          validate: true,
        },
        {
          text: window.strings['url'],
          value: 'url',
          align: 'center',
          width: 100,
          validate: true,
        },
        {
          text: window.strings['status'],
          value: 'status',
          sortable: false,
          align: 'center',
          width: 80,
          validate: false,
        },
        {
          text: window.strings['actions'],
          value: 'actions',
          sortable: false,
          align: 'center',
          width: 80,
          validate: false,
        },
      ]
      if (this.hasCategory) {
        data.splice(1, 0, {
          text: window.strings['category'],
          value: 'category',
          align: 'center',
          width: 120,
          validate: true,
        })
      }
      if (this.hasChargeDay) {
        data.splice(4, 0, {
          text: window.strings['charge_day'],
          value: 'charge_day',
          align: 'center',
          width: 130,
          validate: true,
        })
      }
      if (this.hasSignupFee) {
        data.splice(2, 0, {
          text: window.strings['signup_fee'],
          value: 'signup_fee',
          align: 'center',
          width: 150,
          validate: true,
        })
      }
      if (this.hasMoloni) {
        data.splice(11, 0, {
          text: window.strings['moloni_article_reference'],
          value: 'moloni_product',
          align: 'center',
          width: 190,
          validate: true,
        })
      }
      for (let i = data.length - 1; i > -1; i--) {
        if (data[i].validate && paymentType.pack_fields && paymentType.pack_fields.indexOf(data[i].value) === -1) {
          data.splice(i, 1)
        }
      }
      this.headers = data
    },
    setFields: function () {
      const paymentTypes = Utils.getPaymentTypes()
      const paymentType = paymentTypes
        ? paymentTypes.find(function (p) {
            return p.value === window.gatewayType
          })
        : null
      const hasContents =
        this.user &&
        this.user.configurations &&
        this.user.configurations.menu_items &&
        this.user.configurations.menu_items.indexOf('contents') > -1
          ? true
          : false
      const data = [
        {
          type: 'input',
          field: 'name',
          id: 'name',
          title: window.strings['name'],
          cols: this.hasSignupFee || this.hasCategory ? 6 : 12,
          value: '',
          required: true,
          visible: true,
          included: true,
        },
        {
          type: 'select',
          field: 'category',
          id: 'category',
          title: window.strings['category'],
          items: this.categories,
          cols: 6,
          value: '',
          searchInput: '',
          required: true,
          visible: true,
          included: this.hasCategory,
        },
        {
          type: 'textarea',
          field: 'description',
          id: 'description',
          title: window.strings['description'],
          cols: 12,
          value: '',
          required: false,
          visible: true,
          included: this.hasDescription,
        },
        {
          type: 'select',
          field: 'form',
          id: 'form',
          title: window.strings['type'],
          items: Utils.getPaymentsPacksForms(),
          cols: 12,
          value: '',
          required: false,
          visible: true,
          included: this.hasForm,
        },
        {
          type: 'select',
          field: 'status',
          id: 'status',
          title: window.strings['status'],
          items: Utils.getPaymentsPacksStatus(),
          cols: 6,
          value: '',
          searchInput: '',
          required: true,
          visible: true,
          included: true,
        },
        {
          type: 'input',
          field: 'price',
          id: 'price',
          title: window.strings['price'],
          cols: 6,
          value: '',
          subType: 'numeric',
          required: true,
          visible: true,
          disabled: false,
          included: true,
        },
        {
          type: 'input',
          field: 'signup_fee',
          id: 'signup_fee',
          title: window.strings['signup_fee'],
          cols: 6,
          value: '',
          subType: 'numeric',
          required: false,
          visible: true,
          included: this.hasSignupFee,
        },
        {
          type: 'input',
          field: 'charge_day',
          id: 'charge_day',
          title: window.strings['charge_day'],
          cols: 6,
          value: '',
          subType: 'numeric',
          required: false,
          visible: true,
          disabled: false,
          included: this.hasChargeDay,
        },
        {
          type: 'select',
          field: 'type',
          id: 'type_value',
          title: window.strings['period'],
          items: Utils.getPaymentsPacksTypes(),
          cols: 6,
          value: '',
          searchInput: '',
          required: true,
          visible: true,
          included: true,
        },
        {
          type: 'input',
          field: 'type',
          id: 'type_quantity',
          title: window.strings['duration'],
          cols: 6,
          value: '',
          subType: 'numeric',
          required: true,
          visible: true,
          labelCallback: this.getType,
          width: '250px',
          included: true,
        },
        {
          type: 'checkbox',
          field: 'allow_recurring',
          id: 'allow_recurring',
          title: window.strings['subscription'],
          cols: hasContents ? 4 : 6,
          value: '',
          subType: 'boolean',
          required: true,
          visible: true,
          included: true,
        },
        {
          type: 'checkbox',
          field: 'allow_one_time_payments',
          id: 'allow_one_time_payments',
          title: window.strings['one_time_payment'],
          cols: hasContents ? 4 : 6,
          value: '',
          subType: 'boolean',
          required: true,
          visible: true,
          included: true,
        },
        {
          type: 'checkbox',
          field: 'allow_installments',
          id: 'allow_installments',
          title: window.strings['staggered_payments'],
          cols: hasContents ? 4 : 6,
          value: '',
          subType: 'boolean',
          required: true,
          visible: true,
          included: true,
        },
        {
          type: 'checkbox',
          field: 'temp',
          id: 'temp',
          title: window.strings['temporary'],
          cols: hasContents ? 4 : 6,
          value: '',
          subType: 'boolean',
          required: true,
          visible: true,
          included: true,
        },
        {
          type: 'checkbox',
          field: 'allow_contents',
          id: 'allow_contents',
          title: window.strings['contents'],
          cols: 4,
          value: '',
          subType: 'boolean',
          required: true,
          visible: true,
          included: hasContents,
        },
        {
          type: 'datepicker',
          field: 'start_date',
          id: 'start_date',
          title: window.strings['start_date'],
          cols: 6,
          value: '',
          required: true,
          visible: true,
          menu: false,
          included: true,
        },
        {
          type: 'datepicker',
          field: 'end_date',
          id: 'end_date',
          title: window.strings['end_date'],
          cols: 6,
          value: '',
          required: true,
          visible: true,
          menu: false,
          included: true,
        },
        {
          type: 'input',
          field: 'url',
          id: 'url',
          title: window.strings['url'],
          cols: 12,
          value: '',
          required: true,
          visible: true,
          included: true,
        },
        {
          type: 'input',
          field: 'moloni_product',
          id: 'moloni_product',
          title: window.strings['moloni_article_reference'],
          cols: 12,
          value: '',
          required: false,
          visible: true,
          included: this.hasMoloni,
        },
        {
          type: 'select',
          field: 'contents',
          id: 'contents',
          title: window.strings['contents_for_sale'],
          items: [],
          cols: 12,
          value: '',
          multiple: true,
          required: false,
          visible: true,
          included: this.hasSaleContents,
        },
        {
          type: 'input',
          field: 'employees',
          id: 'employees',
          title: window.strings['employees'],
          cols: 3,
          value: '',
          subType: 'numeric',
          required: false,
          visible: true,
          disabled: false,
          included: this.hasEmployees,
        },
      ]

      for (let i = data.length - 1; i > -1; i--) {
        if (!data[i].included || !paymentType?.pack_fields?.includes(data[i].field)) {
          data.splice(i, 1)
          continue
        }
        if (paymentType?.exclude_required_fields?.includes(data[i].field)) {
          data[i].required = false
        }
      }

      if (this.user?.configurations?.payments_packs?.fields) {
        const jsonFields = JSON.parse(JSON.stringify(this.user.configurations.payments_packs.fields))

        const updatePercentageFields = (fd, price) => {
          let val = fd.value || fd.value === 0 ? parseFloat(fd.value) : ''
          if (val && val > 100) val = 100

          const otherPercentageField = data.find((d) => d.percentage && d.id !== fd.id)

          if (val || val === 0) {
            fd.labelText = val > 0 ? `${Math.round(((price.value * val) / 100) * 100) / 100}€` : ''
            fd.value = val

            if (otherPercentageField) {
              otherPercentageField.value = 100 - val
              otherPercentageField.labelText = `${Math.round(((price.value * otherPercentageField.value) / 100) * 100) / 100}€`
            }
          } else {
            fd.value = ''
            fd.labelText = ''
            if (otherPercentageField) {
              otherPercentageField.value = ''
              otherPercentageField.labelText = ''
            }
          }
        }

        jsonFields.forEach((fd) => {
          fd.id = fd.field
          fd.value = ''
          fd.included = true
          fd.type = 'input'
          fd.title = window.strings[fd.title] || fd.title

          if (fd.percentage) {
            fd.subType = 'numeric'
            fd.labelText = ''

            const priceField = data.find((d) => d.id === 'price')
            priceField.onChange = () => {
              const percentageField = data.find((d) => d.percentage)
              percentageField?.onChange()
            }

            fd.onChange = () => updatePercentageFields(fd, priceField)
          }

          if (fd.index) {
            data.splice(fd.index, 0, fd)
            delete fd.index
          } else {
            data.push(fd)
          }
        })
      }

      this.data = data

      if (this.hasSaleContents) {
        Api.getContents(
          {
            minType: this.contentsMinType,
            fields: ['id', 'name'],
            useCache: true,
          },
          (response) => {
            if (response.success && response.data) {
              for (let i = 0; i < this.data.length; i++) {
                if (this.data[i].id === 'contents') {
                  this.data[i].items = response.data.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))
                  return true
                }
              }
            }
          },
        )
      }
    },
    openItem(item) {
      this.setData(item)
      this.itemToEdit = item
      this.dialogItem = true
    },
    setData: function (item) {
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].id === 'status' && !item) {
          this.data[i].value = 0
          continue
        }
        if (this.data[i].subType === 'boolean' && !item) {
          this.data[i].value = 0
          continue
        }
        if (this.data[i].id === 'start_date' && !item) {
          this.data[i].value = moment().format('YYYY-MM-DD')
          continue
        }
        if (this.data[i].id === 'type_value') {
          if (item) {
            this.data[i].value = item.type ? item.type.split('#')[1] : ''
          } else {
            const typeValueDefault = this.data[i].items.find(function (t) {
              return t.default
            })
            this.data[i].value = typeValueDefault ? typeValueDefault.value : ''
          }
          continue
        }
        if (this.data[i].id === 'type_quantity' && item) {
          this.data[i].value = item.type ? item.type.split('#')[0] : ''
          continue
        }
        if (this.data[i].id === 'price' && item) {
          this.data[i].value = item.price / 100
          continue
        }
        if (this.data[i].id === 'signup_fee' && item) {
          this.data[i].value = item.signup_fee / 100
          continue
        }
        if (this.data[i].id === 'charge_day' && item && !item.charge_day) {
          this.data[i].value = ''
          continue
        }
        if (this.data[i].id === 'contents') {
          this.data[i].value = item && item.contents ? JSON.parse(item.contents) : []
          continue
        }
        this.data[i].value = item ? item[this.data[i].id] : ''
        if (this.data[i].onChange) {
          this.data[i].onChange()
        }
      }
      this.refreshData()
    },
    getData: function () {
      const data = {}
      let typeValue = ''
      let typeQuantity = ''
      let temp = ''
      for (let i = 0; i < this.data.length; i++) {
        let value = this.data[i].value
        if (this.data[i].visible && this.data[i].required && !this.data[i].disabled && !value && value !== 0 && value !== false) {
          return false
        }
        if (this.data[i].subType === 'numeric') {
          value = parseFloat(value)
        }
        if (this.data[i].subType === 'boolean') {
          value = value ? 1 : 0
        }
        if (this.data[i].id === 'price') {
          if (isNaN(value)) {
            value = 0
          }
          value = Math.round(value * 100)
        }
        if (this.data[i].id === 'signup_fee') {
          value = Math.round(value * 100)
        }
        if (this.data[i].id === 'type_value') {
          typeValue = value
          continue
        }
        if (this.data[i].id === 'type_quantity') {
          typeQuantity = value
          continue
        }
        if (this.data[i].id === 'temp') {
          temp = value
        }
        if (this.data[i].id === 'end_date') {
          value = temp ? value : null
        }
        if (this.data[i].id === 'contents') {
          value = value && value.length ? JSON.stringify(value) : ''
        }
        data[this.data[i].id] = value
      }
      data.type = typeQuantity + '#' + typeValue
      data.gateway = window.gatewayType
      return data
    },
    refreshData: function (item) {
      if (item && item.type === 'select') {
        item.searchInput = ''
      }
      let temp = ''
      let category = ''
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].id === 'temp') {
          temp = this.data[i].value
        }
        if (this.data[i].id === 'end_date') {
          if (temp) {
            this.data[i].disabled = false
          } else {
            this.data[i].value = ''
            this.data[i].disabled = true
          }
        }
        if (this.data[i].id === 'category') {
          category = this.data[i].value
        }
        if (this.data[i].id === 'contents') {
          this.data[i].visible = category === this.hasSaleContentsCategory ? true : false
          if (!this.data[i].visible) {
            this.data[i].value = ''
          }
        }
      }
    },
    closeItem() {
      this.dialogItem = false
      this.itemToEdit = null
    },
    saveItem() {
      const self = this
      const data = this.getData()
      if (data) {
        this.$isLoading(true)
        if (this.itemToEdit) {
          data.id = this.itemToEdit.id
          Api.updatePaymentPack(data, function (response) {
            self.$isLoading(false)
            if (response.success) {
              const index = self.items.findIndex(function (i) {
                return i.id === response.data.id
              })
              if (index > -1) {
                self.items[index] = response.data
                self.$set(self.items, index, self.items[index])
              }
              self.closeItem()
            } else {
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          })
        } else {
          Api.newPaymentPack(data, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.items.push(response.data)
              self.closeItem()
            } else {
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          })
        }
      } else {
        self.$alert(window.strings['payments_packs_missing_fields'], '', 'warning', Utils.getAlertOptions())
      }
    },
    deleteItem(item) {
      const self = this
      this.$confirm(window.strings['are_you_sure_delete'], '', 'warning', Utils.getAlertOptions(true, true))
        .then(() => {
          self.$isLoading(true)
          Api.updatePaymentPack(
            {
              id: item.id,
              status: 99,
            },
            function (response) {
              if (response.success) {
                self.getItems()
              } else {
                self.$isLoading(false)
                self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
              }
            },
          )
        })
        .catch(() => {})
    },
    getType(item) {
      try {
        let value = ''
        if (item) {
          value = item.type
        } else {
          const typeValue = this.data.find(function (d) {
            return d.id === 'type_value'
          })
          const typeQuantity = this.data.find(function (d) {
            return d.id === 'type_quantity'
          })
          if (typeValue && typeValue.value && typeQuantity && typeQuantity.value) {
            value = typeQuantity.value + '#' + typeValue.value
          }
        }
        if (value === '1#month') {
          return window.strings['monthly']
        }
        if (value === '1#month') {
          return window.strings['monthly']
        }
        if (value === '3#month') {
          return window.strings['quarterly']
        }
        if (value === '6#month') {
          return window.strings['semiannual']
        }
        if (value === '12#month' || value === '1#year') {
          return window.strings['annual']
        }
        if (value) {
          const split = value.split('#')
          const type = split[1]
          const quantity = parseInt(split[0])
          let key = ''
          if (type === 'day') {
            key = quantity > 1 ? 'days' : 'day'
          }
          if (type === 'week') {
            key = quantity > 1 ? 'weeks' : 'week'
          }
          if (type === 'month') {
            key = quantity > 1 ? 'months' : 'month'
          }
          if (type === 'year') {
            key = quantity > 1 ? 'years' : 'year'
          }
          if (type === 'booking') {
            key = quantity > 1 ? 'bookings' : 'booking'
          }
          return quantity + ' ' + (key ? window.strings[key].toLowerCase() : window.strings[type] ? window.strings[type] : type)
        }
      } catch {}
      return ''
    },
  },
}
</script>
